import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="impressum-container">
            <h1>Kontakt & Impressum</h1>
            <div className="impressum-content">
                <h2>AusspracheTrainer Studie</h2>
                <p>Die Webseite dient ausschließlich der Durchführung der Studie "AusspracheTrainer im Test: Neue Wege in der Sprachbildung der digitalen Zukunft" an der Universität Tübingen.
                    Versuchsleiter ist Daniel Busch, betreuender Linguist und Projektleiter ist Dr. Konstantin Sering (Universität Tübingen). Weitere Informationen finden Sie in der
                    Teilnehmeraufklärung und Einwilligungserklärung, die Sie auf der Startseite herunterladen können.
                </p>

                <h2>Webseiteninhalte</h2>
                <h3>Angaben gemäß § 5 TMG</h3>
                <p>AusspracheTrainer g.e.V.<br />
                    c/o Dr. Oliver Busch<br />
                    Mühlgasse 8,<br />
                    60486 Frankfurt am Main<br />
                </p>
                <h3>Kontakt</h3>
                <p>
                    E-Mail: kontakt@ausspracehtrainer.org<br />
                    Ansprechpartner: Daniel Busch<br />
                </p>

                <h3>Haftungsausschluss</h3>
                <p>Haftung für Inhalte<br />
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
                    zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                    Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
                    erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                    werden wir diese Inhalte umgehend entfernen.</p>
                <p>Haftung für Links<br />
                    Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                    Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Links umgehend entfernen.</p>
                <p>Urheberrecht<br />
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet.
                    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
            </div>
        </div>
    );
};

export default Contact;
